<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SaveButton from "@/components/buttons/save-button";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SaveButton
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';
        return {
            id: id,
            title: `Role`,
            modeName,
            items: [
                {
                    text: "Role"
                },
                {
                    active: true,
                },
            ],

            data: {
                name: null,
                display_name: null,
                description: null,
                permissions: {},
                btn_clicked: false
            },

            keys: [
                'admin',  'member', 'tenancy', 'ctc', 
                'role', 'product', 'amenity', 'agent', 'agency', 'agreement',
            ],

            errors: {},
        };
    },
    computed: {

    },
    async mounted() {
        for (const key of this.keys) {
            const permissionKeys = [
                `view-${key}`,
                `create-${key}`,
                `edit-${key}`,
                `delete-${key}`,
                `export-${key}`
            ];

            for (const permissionKey of permissionKeys) {
                this.$set(this.data.permissions, permissionKey, false);
            }
        }

        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/setting/role/${this.id}`, {
                headers: authHeader(),
            });
            if (result && result.data['code'] === 'success') {
                const role_data = result.data['data']['role']['role'];
                for (const [key, item] of Object.entries(role_data)) {
                    this.data[key] = item;
                }

                for (const permissionKey in this.data.permissions) {
                    if (result.data['data']['role']['permissions'].includes(permissionKey)) {
                        this.data.permissions[permissionKey] = true;
                    }
                }
            }
        }
    },
    methods: {
        async save() {
            if (this.btn_clicked) return ;
            this.btn_clicked = true;
            this.errors = {};

            let result;
            const filteredPairs = Object.entries(this.data);
            const requestData = Object.fromEntries(filteredPairs);
            if (this.modeName === 'Add') {
                result = await axios.post(`${process.env.VUE_APP_APIURL}/setting/role`, requestData, {
                    headers: authHeader(),
                });
            } else {
                result = await axios.put(`${process.env.VUE_APP_APIURL}/setting/role/${this.id}`, requestData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$router.back();
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    }
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                } else if (result.data['code'] === 'permission_denied') {
                    this.$refs.messageModal.showModal('Permission Denied');
                }
                this.btn_clicked = false;
            }
        },

        processedName(key) {
            const processed = key.replace(/-/g, ' ');
            return processed.charAt(0).toUpperCase() + processed.slice(1);
        },

        hasPermission(permissionKey) {
            return this.role.permissions.some((permission) => permission.id === this.permissions[permissionKey].id);
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="card p-3">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <InputGroup type="text" id="role" displayName="Role" v-model="data.name" :error="errors.name" />
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <InputGroup type="text" id="name" displayName="Name" v-model="data.display_name" :error="errors.display_name" class="mb-1" />
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <InputGroup type="text" id="description" displayName="Description" v-model="data.description" :error="errors.description" class="mb-1" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h3 class="headings-color mt-5">Set Permissions</h3>
                    <div class="table-responsive">
                        <table class="table table-bordered text-center">
                            <tr>
                                <th class="grey-cell">Resources</th>
                                <th class="grey-cell">View</th>
                                <th class="grey-cell">Add</th>
                                <th class="grey-cell">Edit</th>
                                <th class="grey-cell">Delete</th>
                                <th class="grey-cell">Export</th>
                            </tr>
                            <tr v-for="key in keys" :key="key">
                                <td class="grey-cell">
                                    <label>{{ processedName(key) }}</label>
                                </td>
                                <td>
                                    <input
                                    type="checkbox"
                                    :name="'view-'+key"
                                    v-model="data.permissions[`view-${key}`]"
                                    />
                                </td>
                                <td v-if="!['report', 'profile', 'log', 'general-setting'].includes(key)">
                                    <input
                                    type="checkbox"
                                    v-model="data.permissions[`create-${key}`]"
                                    />
                                </td>
                                <td v-else></td>
                                <td v-if="!['report', 'log'].includes(key)">
                                    <input
                                    type="checkbox"
                                    v-model="data.permissions[`edit-${key}`]"
                                    />
                                </td>
                                <td v-else></td>
                                <td v-if="!['agreement'].includes(key)">
                                    <input
                                    type="checkbox"
                                    v-model="data.permissions[`delete-${key}`]"
                                    />
                                </td>
                                <td v-else></td>
                                <td v-if="['tenancy', 'product'].includes(key)">
                                    <input
                                    type="checkbox"
                                    v-model="data.permissions[`export-${key}`]"
                                    />
                                </td>
                                <td v-else></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 d-flex justify-content-end">
                    <SaveButton @click="save" />
                </div>
            </div>
        </div>
    </Layout>
</template>

<style>
.table .grey-cell {
    background-color: #C0C3C8;
    color: #2D3748;    
}

.table input[type="checkbox"] {
    appearance: none;
    width: 13px;
    height: 13px;
    background-color: #fff;
    border: 2px solid #555;
    border-radius: 3px;
}

.table input[type="checkbox"]:checked {
    background-color: #FE4727;
    border-color: #FE4727;
    position: relative;
}

.table input[type="checkbox"]:checked::before {
    content: "✔";
    color: white;
    font-size: 8px;
    position: absolute;
    top: 0px;
    left: 1px;
}
</style>